import * as React from "react";
import { List, Datagrid, TextField, TextInput, Show, SimpleShowLayout, Filter } from 'react-admin';
import { MyPagination } from './pagination';


const LoyaltyComenzFilter = (props) => (
    <Filter {...props}>
        <TextInput label="Search" source="q" alwaysOn />
    </Filter>
);

export const LoyaltyComenzList = (props) => (
    <List {...props} title='Логи лояльности' filters={<LoyaltyComenzFilter />} pagination={<MyPagination/>}>
        <Datagrid rowClick="show">
            <TextField source="id" label="ID" sortable={false} />
            <TextField source="shop_id" label="ShopID" sortable={true} />
            <TextField source="order_id" label="OrderID" sortable={true} />
            <TextField source="una_order_id" label="UnaID" sortable={true} />
            <TextField source="loyality_barcode" label="Баркод" sortable={true} />
            <TextField source="date_insert" label="дата" sortable={true} />
        </Datagrid>
    </List>
);

